/**
 * 招待コードを作成します。
 *
 * @param groupName グループ名
 * @returns 招待コード
 */
export async function createInvidationCode(groupName: string): Promise<string> {
    const plainData = new TextEncoder().encode(groupName);
    const key = await window.crypto.subtle.importKey(
        "raw",
        EncryptionKey,
        { name: "AES-CBC" },
        false,
        ["encrypt"],
    );
    const cryptData: ArrayBuffer = await window.crypto.subtle.encrypt(
        { name: "AES-CBC", iv: InitialVector },
        key,
        plainData,
    );

    return Array.from(new Uint8Array(cryptData), convertUint8ToHex).join("");
}

//------------------------------------------------------------------------------
// Helpers
//------------------------------------------------------------------------------

const InitialVector = new Uint8Array([
    0xa0, 0xc3, 0x97, 0x30, 0x15, 0xe2, 0xe5, 0xf4, 0xf0, 0x2f, 0xc5, 0x31,
    0xec, 0xb4, 0x9f, 0x95,
]);
const EncryptionKey = new Uint8Array([
    0x8a, 0x17, 0xb4, 0xce, 0xfb, 0x83, 0xf3, 0xb3, 0x7d, 0x5c, 0xf4, 0x1b,
    0xe8, 0xf9, 0x9e, 0x41,
]);

function convertUint8ToHex(value: number): string {
    return value.toString(16).toUpperCase().padStart(2, "0");
}
